/* eslint-disable */
import React from 'react';
import axios from 'axios';
import inLocalStorageJwt from './inLocalStorageJwt';
import { toast } from 'react-toastify';

const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();

const createActivity = async (userId, activity, type, object, objectId = null, play_pause_time=null) => {
    // console.log("api caled");
    const AuthTokenObject = inLocalStorageJwt.getAuthToken();
    const AuthToken = AuthTokenObject.authToken;
    const options = {
        headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
        }
    };
    const postData = {
        user: userId,
        activity,
        type,
        object,
        objectId,
        play_pause_time
    };
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user-activity/create/`, postData, options)
        .then(async () => {
        // console.log(res);
        // taskContext.fetchTasks();
        })
        .catch((error) => {
        console.log(error);
        });
};

const getUserData = async (userId) => {
    // console.log("api caled");
    const AuthTokenObject = inLocalStorageJwt.getAuthToken();
    const AuthToken = AuthTokenObject.authToken;
    const options = {
        headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
        }
    };
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/${userId}`, options)
        .then(async (res) => {
        // console.log(res.data.user);
        return res.data.user;
        // taskContext.fetchTasks();
        })
        .catch((error) => {
        console.log(error);
        });
    return response;
};

const fetchUsersForChart = async (sponsorEmail) => {
    const perPage = 100;
    const page = 1;
    const offset = (page - 1) * perPage;
    const AuthTokenObject = inLocalStorageJwt.getAuthToken();
    const AuthToken = AuthTokenObject.authToken;
    const options = {
        headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
        }
    };
    // setLoading(true);
    var filters = `?bms_sponsor=${sponsorEmail}&skip=${offset}&limit=${perPage}&gen=1`;
    const resp = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users${filters}`, options)
      .then(async (res) => {
        // setReportList(res.data.dsreports);
        // console.log(res.data.result);
        return res.data.result.users;
        // setData(res.data.result.users);
        // setTotalRows(res.data.result.totalUsers);
      })
      .catch((error) => {
        console.log(error);
      });
    return resp;
};
const fetchUsersForList = async (sponsorEmail,perPage, currentPage) => {
    const offset = (currentPage - 1) * perPage;
    const AuthTokenObject = inLocalStorageJwt.getAuthToken();
    const AuthToken = AuthTokenObject.authToken;
    const options = {
        headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
        }
    };
    var filters = `?bms_sponsor=${sponsorEmail}&skip=${offset}&limit=${perPage}&gen=1`;
    const resp = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users${filters}`, options)
      .then(async (res) => {
        const users = res.data.result.users;
        const totalUsers = res.data.result.totalUsers;
        return {users, totalUsers};
      })
      .catch((error) => {
        console.log(error);
      });
    return resp;
};
const getTaskActivity = async (userId, actype, taskId) => {
    // console.log("api caled");
    const AuthTokenObject = inLocalStorageJwt.getAuthToken();
    const AuthToken = AuthTokenObject.authToken;
    const options = {
        headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
        }
    };
    const filter = `?user=${userId}&actype=${actype}&objectId=${taskId}`;
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user-activity/get-task-activity${filter}`, options)
        .then(async (res) => {
        // console.log(res.data.user);
        return res.data.taskActivity;
        // taskContext.fetchTasks();
        })
        .catch((error) => {
        console.log(error);
        });
    return response;
};
const getTaskDetails = async (taskId) => {
    // console.log("api caled");
    const AuthTokenObject = inLocalStorageJwt.getAuthToken();
    const AuthToken = AuthTokenObject.authToken;
    const options = {
        headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
        }
    };
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/tasks/view/${taskId}`, options)
        .then(async (res) => {
        // console.log(res.data.user);
        return res.data.task;
        // taskContext.fetchTasks();
        })
        .catch((error) => {
        console.log(error);
        });
    return response;
};
const getViewerActivity = async (userId, actype, trainingId) => {
  // console.log("api caled");
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };
  const filter = `?user=${userId}&actype=${actype}&objectId=${trainingId}`;
  const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user-activity/get-task-activity${filter}`, options)
      .then(async (res) => {
      // console.log(res.data);
      return res.data.taskActivity;
      // taskContext.fetchTasks();
      })
      .catch((error) => {
      console.log(error);
      });
  return response;
};
const getTrainingDetails = async (trainingId) => {
  // console.log("api caled");
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };
  const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/trainings/view/${trainingId}`, options)
      .then(async (res) => {
      // console.log(res.data.user);
      return res.data.training;
      // taskContext.fetchTasks();
      })
      .catch((error) => {
      console.log(error);
      });
  return response;
};
/**
 * payment code
 * @param {*} plan 
 */
function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === '[object Date]'
  }
  
  function isObj(val) {
    return typeof val === 'object'
  }
  
   function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val)
    } else {
      return val
    }
  }
  
  function buildForm({ action, params }) {
    const form = document.createElement('form')
    form.setAttribute('method', 'post')
    form.setAttribute('action', action)
  
    Object.keys(params).forEach(key => {
      const input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', key)
      input.setAttribute('value', stringifyValue(params[key]))
      form.appendChild(input)
    })
  
    return form
  }
  
function post(details) {
    const form = buildForm(details)
    document.body.appendChild(form)
    form.submit()
    form.remove()
}
const makePurchase = async (plan) => {
    const AuthTokenObject = await inLocalStorageJwt.getAuthToken();
    const AuthToken = AuthTokenObject.authToken;
    const options = {
        headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
        }
    };
    const postData = {
        planId: plan
    };

    const chksumresp = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/plans/make-purchase`, postData, options)
        .then(async (res) => {
        return res.data.subscription;
        // await redirectToPayU(res.data.subscription);
        // taskContext.fetchTasks();
        })
        .catch((error) => {
        console.log(error);
        });
    // var information={
    //     action:"https://securegw-stage.paytm.in/order/process",
    //     params: chksumresp
    // };
    // post(information);
    return chksumresp;
};

const makeCoursePurchase = async (courseId, durationId) => {
  const AuthTokenObject = await inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };
  const postData = {
    courseId,
    durationId
  };

  const chksumresp = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/courses/make-purchase`, postData, options)
      .then(async (res) => {
      return res.data.course;
      // await redirectToPayU(res.data.subscription);
      // taskContext.fetchTasks();
      })
      .catch((error) => {
      console.log(error);
      });
  // var information={
  //     action:"https://securegw-stage.paytm.in/order/process",
  //     params: chksumresp
  // };
  // post(information);
  return chksumresp;
};

const makePrePayment = async (userId, amount) => {

  const options = {
      headers: {
      'Content-Type': 'application/json',
      }
  };
  const postData = {
    userId,
    amount
  };

  const chksumresp = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/prepayment`, postData, options)
      .then(async (res) => {
      return res.data.subscription;
      // await redirectToPayU(res.data.subscription);
      // taskContext.fetchTasks();
      })
      .catch((error) => {
      console.log(error);
      });
  // var information={
  //     action:"https://securegw-stage.paytm.in/order/process",
  //     params: chksumresp
  // };
  // post(information);
  return chksumresp;
};

const makeQuickPayment = async (postData) => {

  const options = {
      headers: {
      'Content-Type': 'application/json',
      }
  };

  const chksumresp = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/quickpayment`, postData, options)
      .then(async (res) => {
      return res.data.subscription;
      // await redirectToPayU(res.data.subscription);
      // taskContext.fetchTasks();
      })
      .catch((error) => {
      console.log(error);
      });
  // var information={
  //     action:"https://securegw-stage.paytm.in/order/process",
  //     params: chksumresp
  // };
  // post(information);
  return chksumresp;
};
const redirectToPayU = async (pd) => {
    // console.log("pd", pd);
    await bolt.launch({
        key: pd.key,
        txnid: pd.txnid, 
        hash: pd.hash,
        amount: pd.amount,
        firstname: pd.firstname,
        email: pd.email,
        phone: pd.phone,
        productinfo: pd.productinfo,
        lastname: pd.lastname,
        surl : pd.surl,
        furl: pd.furl
    }, {
        responseHandler: function (response) {
        // your payment response Code goes here
        fetch(process.env.REACT_APP_API_BASE_URL + 'payment/payumoney/response', {
          method: 'POST',
          headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json'
          },
          body: JSON.stringify(response.response)
        })
        .then(function (a) {
           return a.json(); 
         })
        .then(function (json) {
          console.log(json);
         });
      },
      catchException: function (response) {
        // the code you use to handle the integration errors goes here
        // Make any UI changes to convey the error to the user
      }
    });
}

const getDsrData = async (userId) => {
    // console.log("api caled", userId);
    const AuthTokenObject = inLocalStorageJwt.getAuthToken();
    const AuthToken = AuthTokenObject.authToken;
    const postData = {
        createdBy: userId,
        skip: 0,
        limit: 7
    };
    const options = {
        headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
        }
    };
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/dsreports`, postData, options)
    .then(async (res) => {
      // setReportList(res.data.dsreports);
    //   console.log(res.data.data.dsreports.length);
      return res.data.data.dsreports;
    })
    .catch((error) => {
      console.log(error);
    });
    return response;
};

const deleteDsrData = async (dsrId) => {
    const AuthTokenObject = inLocalStorageJwt.getAuthToken();
    const AuthToken = AuthTokenObject.authToken;
    const options = {
        headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AuthToken}`,
        }
    };
    const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/dsreports/deletedsr/${dsrId}`, options)
    .then(async (res) => {
      return res.status;
    })
    .catch((error) => {
      console.log(error);
    });
    return response;
}

const getRefreshToken = async () => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const oldRefreshToken = inLocalStorageJwt.getRefreshToken();
  const postData = {
    refreshToken: oldRefreshToken.refreshToken,
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
    }
  };
  const response = await  axios.post(`${process.env.REACT_APP_API_BASE_URL}auth/refresh-tokens`, postData, options)
            .then((res) => {
              const respData = res.data;

              if (respData.tokens) {
                const tokens = respData.tokens;
                // alert(respData.tokens.access);
                // localStorage.setItem('bms_user', JSON.stringify(res.data));
                // inMemoryJwt.setToken(respData.tokens.access);
                const authToken = tokens.access.token;
                const authTokenExpiry = tokens.access.expires;
                inLocalStorageJwt.setAuthToken(authToken,authTokenExpiry);
                const refreshToken = tokens.refresh.token;
                const refreshTokenExpiry = tokens.refresh.expires;
                inLocalStorageJwt.setRefreshToken(refreshToken,refreshTokenExpiry);
                inLocalStorageJwt.setLoggedinUserData(respData.user);
                //console.log(res.data);
                return true;
              }
            })
            .catch((error) => {
              // what now?
              // console.log(error.response.data.message);
              // setSubmitting(false);
              // Error
              if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest
                // in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error);
              }
              return true;
            });
      return true;
}

const deleteOrderByCustomId = async (orderId) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };
  const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/orders/custom/${orderId}`, options)
  .then(async (res) => {
    return res.status;
  })
  .catch((error) => {
    console.log(error);
  });
  return response;
}
const deleteALlIncompleteOrder = async () => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };
  const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/orders/delete-incomplete-orders`, options)
  .then(async (res) => {
    return res.status;
  })
  .catch((error) => {
    console.log(error);
  });
  return response;
}
const getAllOrders = async (user,perPage, currentPage) => {
  const offset = (currentPage - 1) * perPage;
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };
  var filters = `?user=${user}&skip=${offset}&limit=${perPage}`;
  const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/orders/get-all${filters}`, options)
  .then(async (res) => {
    console.log(res);
    return res.data.result;
  })
  .catch((error) => {
    console.log(error);
  });
  return response;
}
const createViewer = async (ctype, ctyperef, viewer) => {
  const AuthTokenObject = await inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };
  const postData = {
      ctype,
      ctyperef,
      viewer
  };

  const viewerdata = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/viewers/create`, postData, options)
      .then(async (res) => {
      return res.data.viewer;
      // await redirectToPayU(res.data.subscription);
      // taskContext.fetchTasks();
      })
      .catch((error) => {
      console.log(error);
      });
  // var information={
  //     action:"https://securegw-stage.paytm.in/order/process",
  //     params: chksumresp
  // };
  // post(information);
  return viewerdata;
};


const updateWorkApplication = async (appId, appData) => {
  // console.log(appId);
  const AuthTokenObject = await inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };

  const formdata = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/formdata/update/${appId}`, appData, options)
      .then(async (res) => {
      return res.data.formdata;
      // await redirectToPayU(res.data.subscription);
      // taskContext.fetchTasks();
      })
      .catch((error) => {
        console.log(error);
      });
  // var information={
  //     action:"https://securegw-stage.paytm.in/order/process",
  //     params: chksumresp
  // };
  // post(information);
  return formdata;
};

const getWorkApplication = async (appId) => {
  // console.log("api caled", userId);
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };
  const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/formdata/applicant-form/${appId}`, options)
  .then(async (res) => {
    // setReportList(res.data.dsreports);
    return res.data.applicationData;
  })
  .catch((error) => {
    console.log(error);
  });
  return response;
};

const addPartner = async (userData) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const postData = {
    fname: userData.name,
    lname: '-',
    email: userData.email,
    phone: userData.phone,
    role: 'user',
    is_sponsored: true,
    bms_sponsor: getLoggedinUserData.email
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  const userD = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/users/create`, postData, options)
      .then(async (res) => {
        // setReportList(res.data.dsreports);
        // console.log(res.data);
        toast.success(`${res.data.message}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      })
      .catch((error) => {
        console.log(error);
        toast.error(`${error.response.data.message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  return userD;
}

const getCategories = async (userId, type, searchStr,field, catId) => {
  // console.log("api caled");
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };
  const searchQuery = `?type=${type}&searchstr=${searchStr}&field=${field}`;
  let url = '';
  if(catId){
    url = `${process.env.REACT_APP_API_BASE_URL}/categories/${userId}/${catId+searchQuery}`;
  }else{
    url = `${process.env.REACT_APP_API_BASE_URL}/categories/${userId+searchQuery}`;
  }
  const response = await axios.get(url, options)
      .then(async (res) => {
      // console.log(res.data.data.catData);
      return res.data.data.catData;
      // taskContext.fetchTasks();
      })
      .catch((error) => {
        console.log(error);
      });
  return response;
};

const getCatInfo = async (catId) => {
  // console.log("api caled", catId);
  if (!catId) {
    console.log("undefined");
    return;
  }
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };
  let url = '';

  url = `${process.env.REACT_APP_API_BASE_URL}/categories/view/${catId}`;

  const response = await axios.get(url, options)
      .then(async (res) => {
      // console.log(res.data);
      return res.data.category;
      // taskContext.fetchTasks();
      })
      .catch((error) => {
        console.log(error);
      });
  return response;
};

const getAllCategories = async (userId) => {
  // console.log("api caled");
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };
  let url = `${process.env.REACT_APP_API_BASE_URL}/categories/all/${userId}`;
  const response = await axios.get(url, options)
      .then(async (res) => {
      // console.log(res.data.data);
      return res.data.data;
      // taskContext.fetchTasks();
      })
      .catch((error) => {
        console.log(error);
      });
  return response;
};
const getTrainingCount = async (catId) => {
  // console.log("api caled");
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };
  const  url = `${process.env.REACT_APP_API_BASE_URL}/categories/element-counting/${catId}`;

  const response = await axios.get(url, options)
      .then(async (res) => {
      // console.log(res.data);
      return res.data.counting;
      // taskContext.fetchTasks();
      })
      .catch((error) => {
        console.log(error);
      });
  return response;
};

const ifUplineOrDownline = async (rootId, targetId) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${AuthToken}`,
    }
  };
  const  url = `${process.env.REACT_APP_API_BASE_URL}/users/check-upline-downline/${rootId}/${targetId}`;
  const response = await axios.get(url, options)
      .then(async (res) => {
      // console.log(res.data);
      if(res.data.result.length > 0){
        return res.data.result[0];
      }
      return res.data.result;
      // taskContext.fetchTasks();
      })
      .catch((error) => {
        console.log(error);
      });
  return response;
}

const findLiveViewersByTraining = async (userId, page, perPage, gen) => {
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
    headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${AuthToken}`,
    }
  };
  const offset = (page - 1) * perPage;

  var filters = `?skip=${offset}&limit=${perPage}&gen=${gen}&ctype=Training`;
  const  url = `${process.env.REACT_APP_API_BASE_URL}/viewers/all-live/${userId}${filters}`;
  const response = await axios.get(url, options)
      .then(async (res) => {
      return res.data.data;
      // taskContext.fetchTasks();
      })
      .catch((error) => {
        console.log(error);
      });
  return response;
}

const requestEmailChangeOtp = async (newEmail) => {
  // console.log(appId);
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };
  const userBody = {email: newEmail};
  const userData = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/users/request-email-change-otp/`, userBody, options)
      .then(async (res) => {
        toast.success(`${res.data.message}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return {status: 200, email: res.data.newEmail};
      })
      .catch((error) => {
        console.log(error);
        toast.error(`${error.response.data.message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return {status: 400, message: error.response.data.message};
      });
  return userData;
};

const verifyEmailChangeOtp = async (otp) => {
  // console.log(appId);
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };
  const userBody = {email_otp: otp};
  const userData = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/users/verify-email-change-otp/`, userBody, options)
      .then(async (res) => {
        toast.success(`${res.data.message}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        inLocalStorageJwt.updateLoggedinUserData('email', res.data.newEmail);
        return {status: 200, email: res.data.newEmail};
      })
      .catch((error) => {
        console.log(error);
        toast.error(`${error.response.data.message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return {status: 400, message: error.response.data.message};
      });
  return userData;
};

const editApplicantBasicDetails = async (reqBody) => {
  // console.log(appId);
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };
  const userData = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/formdata/save-basic-details/`, reqBody, options)
      .then(async (res) => {
        toast.success(`${res.data.message}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return {status: 200, message: res.data.message};
      })
      .catch((error) => {
        console.log(error);
        toast.error(`${error.response.data.message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return {status: 400, message: error.response.data.message};
      });
  return userData;
};

const placeOrder = async (plan, couponCode) => {
  // console.log(appId);
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };
  const postBody = {
    planId: plan,
    couponCode
  };
  const userData = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/plans/place-order/`, postBody, options)
      .then(async (res) => {
        toast.success(`${res.data.message}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return {status: 200, subscription: res.data.subscription, message: res.data.message};
      })
      .catch((error) => {
        console.log(error);
        toast.error(`${error.response.data.message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return {status: 400, subscription: '', message: error.response.data.message};
      });
  return userData;
};


const saveRpPaymentStatus = async (orderId, response) => {
  // console.log(appId);
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const options = {
      headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
      }
  };
  
  const userData = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/plans/update-rp-payment-status/${orderId}`, response, options)
      .then(async (res) => {
        toast.success(`${res.data.message}`, {
          position: 'top-right',
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return {status: 200, subscription: res.data.subscription, message: res.data.message};
      })
      .catch((error) => {
        console.log(error);
        toast.error(`${error.response.data.message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return {status: 400, subscription: '', message: error.response.data.message};
      });
  return userData;
};
export default {
    createActivity,
    getUserData,
    getTaskActivity,
    getViewerActivity,
    getTaskDetails,
    getTrainingDetails,
    makePurchase,
    redirectToPayU,
    fetchUsersForChart,
    fetchUsersForList,
    getDsrData,
    deleteDsrData,
    getRefreshToken,
    deleteOrderByCustomId,
    deleteALlIncompleteOrder,
    getAllOrders,
    updateWorkApplication,
    createViewer,
    getWorkApplication,
    addPartner,
    getCategories,
    getCatInfo,
    getTrainingCount,
    makeCoursePurchase,
    makePrePayment,
    makeQuickPayment,
    ifUplineOrDownline,
    getAllCategories,
    findLiveViewersByTraining,
    requestEmailChangeOtp,
    verifyEmailChangeOtp,
    editApplicantBasicDetails,
    placeOrder,
    saveRpPaymentStatus
};
