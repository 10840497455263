/* eslint-disable */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Link,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  IconButton,
  Grid
} from '@material-ui/core';

import { Search as SearchIcon } from 'react-feather';
import TrainingContext from 'src/contexts/TrainingContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Create from '../../categories/CategoryListView/Create';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  addButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className,trainings, ...rest }) => {
  const classes = useStyles();
  const { catid } = rest;
  const trainingContext = useContext(TrainingContext);
  const [searchString, setSearchString] = React.useState('');
  const [selectedTr, setSelectedTr] = React.useState(null);
  const navigate = useNavigate();

  // const trainings = trainings;

  function handleViewDetail(trainingId) {
    navigate(`/app/training/details-page/${trainingId}`);
  }
  useEffect(() => {
    if(searchString.length >= 3 || searchString.length === 0){
      trainingContext.filterTraining(searchString);
    }
  }, [searchString]);
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
        // style={{paddingRight: '10px'}}
      >
        {/* <Button className={classes.importButton}>
          Import
        </Button> */}
        {/* <Button className={classes.exportButton}>
          Export
        </Button> */}
        {/* <Link
          to="/app/training/create"
          component={RouterLink}
          className={classes.exportButton}
        >
          <Button
            color="primary"
            variant="contained"
          >
            Go YT Live
          </Button>
        </Link> */}
        {catid && <><Create catid={catid} /><Link
          to={`/app/tcategories/${catid}/trainings/create`}
          component={RouterLink}
          className={classes.addButton}
        >
          <Button
            color="primary"
            variant="contained"
          >
            Add training
          </Button>
        </Link></>}
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box mt={3}>
              <Grid
                container
                spacing={1}
              >
                <Grid
                  item
                  key='search-box-1'
                  lg={4}
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    onChange={e => setSearchString(e.target.value)}
                    name="filter"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            onClick={() => {
                              trainingContext.filterTraining(searchString);
                            }}
                          >
                            <SvgIcon
                              fontSize="small"
                              color="action"
                            >
                              <SearchIcon />
                            </SvgIcon>
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search training"
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  key='auto-complete-1'
                  lg={6}
                  md={4}
                  xs={10}
                >
                  {trainings &&
                  (<Autocomplete
                    id="task-drop-down"
                    name="type"
                    fullWidth
                    // onChange={handleChange}
                    onChange={(event, newValue) => {
                      setSelectedTr(newValue);
                    }}
                    autoHighlight
                    value={selectedTr}
                    options={trainings}
                    getOptionLabel={(option) => option.name+' by '+option.trainer || ''}
                    getOptionSelected={(option) => option.name || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Go to Specific Training"
                        variant="outlined"
                      />
                    )}
                  />
                  )}
                </Grid>
                <Grid
                  item
                  key='search-box-2'
                  lg={2}
                  md={2}
                  xs={2}
                >
                  {selectedTr &&
                  (<IconButton
                    onClick={() => {
                      handleViewDetail(selectedTr.id);
                    }}
                  >
                    <SvgIcon
                      fontSize="large"
                      color="action"
                    >
                      <SearchIcon />
                    </SvgIcon>
                  </IconButton>
                  )}
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
