/* eslint-disable */
import React, { useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import ApiCall from 'src/utils/ApiCall';
import axios from 'axios';
import Page from 'src/components/Page';
import Breadcrumbs from 'src/components/BreadCrumbs/BreadCrumbs';
// import TDetail from './TDetail';
// import Audio from './Audio';
// import Video from './Video';
// import Document from './Document';
import Premiere from './Premiere';
import PremiereCreate from './PremiereCreate';
import Viewers from './Viewers';
import TrainingContext from 'src/contexts/TrainingContext';
import ViewTrainingCard from '../TrainingListView/ViewTrainingCard';

const useStateRef = require('react-usestateref');

// import ProfileDetails from './ProfileDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  trainingCard: {
    height: '100%'
  },
}));

const Account = () => {
  const classes = useStyles();
  let { trainingId } = useParams();
  const navigate = useNavigate();
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const loggidinUser = inLocalStorageJwt.getLoggedinUserData();
  const [trainingData, SetTrainingData] = useStateRef({});
  const [catId, SetcatID] = useStateRef('');
  const [premiereData, SetPremiereData] = useStateRef({});
  const [paidUsers, SetPaidUsers] = useStateRef([]);
  const [catInfo, setCatInfo] = useStateRef();
  const itemsArray = [
    { page: 'Dashboard', href: '/app/dashboard' },
    { page: 'Folders', href: '/app/tcategories' },
    { page: catInfo && catInfo.name, href: '/app/tcategories/'+catId+'/trainings' },

  ];

  const getCatInfo = async () => {
    const catData = await ApiCall.getCatInfo(catId);
    setCatInfo(catData);
  };
  const AuthToken = AuthTokenObject.authToken;
  const options1 = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  async function getPremiere(trainingId) {
    if (trainingId) {
      SetTrainingData({ id: trainingId });
      await axios.get(`${process.env.REACT_APP_API_BASE_URL}/premieres/get-detail/${trainingId}`, options1)
        .then(async (res) => {
          if(res.data.premiere){
            SetPremiereData(res.data.premiere);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  const fetchPaidDownlines = async () => {
    // setLoading(true);
    // const response = await axios.get(
    //   `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
    // );
    const filters = `?bms_sponsor=${loggidinUser.email}&skip=0&limit=${100}&role=paid_user&gen=1`;
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users${filters}`, options1)
      .then(async (res) => {
        // setReportList(res.data.dsreports);
        // console.log(res.data.result);
        SetPaidUsers(res.data.result.users);
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    catId && getCatInfo(catId);
  }, [catId]); // Or [] if effect doesn't need props or state

  useEffect(() => {
    async function getTraining() {
      if (trainingId) {
        SetTrainingData({ id: trainingId });
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/trainings/view/${trainingId}`, options1)
          .then(async (res) => {
            // console.log(res.data.training);
            if(!res.data.training){
              navigate(-1);
            }
            SetTrainingData(res.data.training);
            SetcatID(res.data.training.category.id);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    async function getPremiere(trainingId) {
      if (trainingId) {
        SetTrainingData({ id: trainingId });
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/premieres/get-detail/${trainingId}`, options1)
          .then(async (res) => {
            if(res.data.premiere){
              SetPremiereData(res.data.premiere);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    getTraining();
    getPremiere(trainingId);
    fetchPaidDownlines();
  }, []); // Or [] if effect doesn't need props or state

  async function setPremieredat(premdata) {
    SetPremiereData(premdata);
  };
  const trainingContextVal = {
    getPremiere,
    setPremieredat
  };
  return (
    <TrainingContext.Provider value={trainingContextVal}>
    <Page
      className={classes.root}
      title="Training Details"
    >
      <Container maxWidth="lg">
        <Grid container>
          <Grid
            item
            key="098997"
            style={{ marginLeft: '0px', marginBottom: '16px' }} // Adjust as needed
          >
            <Breadcrumbs
              itemsArray={itemsArray}
              className={classes.spacer}
              lastPage={{ page: trainingData && trainingData.name }}
            />
          </Grid>
        </Grid>    
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={6}
            xs={12}
          >
            <ViewTrainingCard
              className={classes.trainingCard}
              training={trainingData}
              paidUsers={paidUsers}
            />
          </Grid>
          {/* <Grid
            item
            lg={12}
            md={6}
            xs={12}
          >
            <TDetail trainingData={trainingData} />
          </Grid> */}
          {/* <Grid
            item
            lg={12}
            md={6}
            xs={12}
          >
            <Video trainingData={trainingData} />
          </Grid> */}
          {
            trainingData.createdBy === loggidinUser.id
            && (
              <Grid
                item
                lg={12}
                md={6}
                xs={12}
              >
                <Viewers trainingData={trainingData} />
              </Grid>
            )
          }
          <Grid
            item
            lg={12}
            md={6}
            xs={12}
          >
            {/* <Document trainingData={trainingData} /> */}
          </Grid>
          <Grid
            item
            lg={12}
            md={6}
            xs={12}
          >
            <PremiereCreate trainingData={trainingData} />
            <Premiere premiereData={premiereData} trainingData={trainingData} />
          </Grid>
        </Grid>
      </Container>
    </Page>
    </TrainingContext.Provider>
  );
};

export default Account;
